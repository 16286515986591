.menu-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  background-color: #492cbb;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  height: 60px;
  margin: 20px auto 0 auto;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(10px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-family: "Poppins", sans-serif;
  padding: 0 16px;
}

.menu-logo-container {
  margin: 0;
  padding: 0;
}

.menu-logo {
  width: 100px;
  height: auto;
}

.menu-close-buttons {
  display: flex;
  gap: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.menu-close-button-purple {
  color: #872b93;
}

.menu-close-button-light {
  color: #ae70b6;
}

.menu-close-button-lighter {
  color: #c99fce;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 320px;
  height: 260px;
  margin: 0 auto;
  background-color: rgba(214, 208, 240, 0.7);
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.menu-title {
  font-size: 20px;
  font-weight: 600;
  color: #6b4eff;
  margin-bottom: 16px;
}

.menu-content-buttons {
  margin-top: "-20px";
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.menu-content-button {
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  font-family: "Graphik", sans-serif;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 0;
}

.menu-content-button:hover {
  text-decoration: underline;
}

.menu-decorative-line {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  width: 360px;
  height: auto;
  padding: 8px;
}

.menu-decorative-middle {
  position: absolute;
  top: 480px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 180px;
  height: 160px;
  margin: 16px 0;
}

.menu-decorative-left {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-150%);
  z-index: 1;
  width: 137px;
  height: 169px;
  margin: 16px 0;
  margin-right: 30px;
}

.menu-decorative-right {
  position: fixed;
  bottom: 260px;
  right: 6%;
  transform: translateX(-10%);
  z-index: 1;
  width: 100px;
  height: 87px;
  margin: 16px -30px 0px;
}

.bottom-navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.bottom-navigation-inner {
  width: 360px;
}
