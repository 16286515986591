.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 12px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  height: 58px;
  z-index: 1000;
  border-radius: 0;
  margin-top: 20px;
}

.project-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #53565a;
}

.project-name {
  font-weight: 500;
  font-size: 14px;
  color: #53565a;
}

.arrow-icon {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
  margin-right: 8px;
}

.arrow-icon-open {
  transform: rotate(180deg);
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
}

.button-active {
  font-weight: bold;
  color: #6b4eff;
}

.button-inactive {
  color: #53565a;
}

.icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
