.time-filter-container {
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 50;
  overflow: auto;
  padding-bottom: 140px;
  top: 0;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.project-button {
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
  padding: 0 2rem;
  color: gray;
  height: 45px;
  border-bottom: 6px solid #fff;
}

.project-button.active {
  color: #492cbb;
  border-bottom: 6px solid #492cbb;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  border: 2px solid #edeaf3;
  border-radius: 24px;
  padding: 14px;
  width: 90%;
  margin: auto;
}

.search-icon {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}

.search-bar input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.sprint-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
}

.sprint-info {
  display: flex;
  align-items: center;
}

.sprint-info-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #edeaf3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.sprint-info-icon img {
  width: 36px;
  height: 36px;
}

.no-sprints {
  text-align: center;
  color: gray;
  font-size: 1.25rem;
  margin-top: 2rem;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top: 2px solid #eee9ff;
  padding: 1rem;
  z-index: 10;
}

.footer-profile {
  text-align: center;
}

.footer-divider {
  height: 2rem;
  border-right: 2px solid #eee9ff;
  margin: 0 1rem;
}

.footer-selected-sprint {
  display: flex;
  align-items: center;
}

.footer-arrow {
  width: 36px;
  transform: rotate(180deg);
  margin-right: 0.5rem;
}

.project-name {
  text-align: left;
  padding: 1rem;
  position: relative;
}

.project-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #492cbb;
  margin-left: 1rem;
}

.underline {
  height: 4px;
  background-color: #492cbb;
  width: 50%;
  margin-left: 1rem;
  border-radius: 2px;
}
