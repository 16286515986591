.container {
  background-color: white;
  border-radius: 0.5rem;
  margin-top: 0px;
  width: 320px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.headerTitle {
  font-weight: bold; 
  color: #53565A;      
}


.colorSolid {
  width: 1.5rem;
  height: 0.25rem;
  margin-right: 0.25rem;
  background-color: #492cbb;
}

.colorDashed {
  width: 1.5rem;
  height: 0.25rem;
  margin-right: 0.25rem;
  border-top: 2px dashed #492cbb;
}

.metricButton {
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  border-width: 1px;
  font-size: 0.875rem;
  font-weight: normal;
  cursor: pointer;
}

.metricEngagement {
  color: #b16000;
  border-color: #b16000;
}

.metricCaring {
  color: #6d2077;
  border-color: #6d2077;
}

.metricSentiment {
  color: #0a8f98;
  border-color: #0a8f98;
}

.metricInactive {
  opacity: 0.5;
}

.hidden {
  display: none;
}
