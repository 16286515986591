@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  background-color: #FAFAFA;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  min-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dot{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  margin-left: 8px;
}


/*  Rings Styling Taken from: https://www.chilitime.design/2018/09/ActivityRings/ */

.RingContainer{
  position: relative;
  svg{
    position: absolute;
    top: 0;
    left: 0;
  }

  .big-number{
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
  }
}

.ActivityRings {
  height: 100%;
  width: 100%;
  transition: all ease 0.14s;
  
  .ring {
    transform-origin: 50%;
  }  
  .completed {
    animation: RingProgress 1s ease-in-out forwards;
    stroke-linecap: round;
  }
  circle {
      fill: none;
  }

  &:hover{
    opacity:0.85;
  }
}

$purple: rgba(78, 42, 194, 1);
$gray: rgba(83, 86, 90, 1);
$light-gray: rgba(151, 153, 155, 1);
$light-purple: #F4F2FA;


.text-purple{
  color:$purple;
}

.border-purple{
  border-color:$purple;
}

.text-gray{
  color:$gray;
}

.text-light-gray{
  color:$light-gray;
}

.bg-purple{
  background-color:$purple;
}

.bg-light-purple{
  background-color:$light-purple;
}

@keyframes RingProgress {
  0% {
      stroke-dasharray: 0 100;
  }
}

.fade-in{
  animation: fade-in 0.27s ease-in-out forwards;
}

.fade-in-30{
  animation: fade-in-30 0.27s ease-in-out forwards;
}

.slide-menu{
  transition: all ease 0.4s;
  &.show{
    bottom:0px;
  }
  &.hide{
    bottom:-100%;
  }
}

.spline-numbers{
  mask-image: url('../src/img/numbers_spline.png');
  -webkit-mask-image: url('../src/img/numbers_spline.png');
  &.caring_individual{
    background-color: #862B92;
  }
  &.sentiment_individual{
    background-color:#5EBCBB;
  }
  &.engagement_individual{
    background-color:#E9CC4C;
  }
  &.all_rounder{
    background-color:#E9CC4C;
  }
}

.spline-purple{
  mask-image: url('../src/img/purple_spline.png');
  -webkit-mask-image:  url('../src/img/purple_spline.png');
  &.caring_individual{
    background-color: #862B92;
  }
  &.sentiment_individual{
    background-color:#5EBCBB;
  }
  &.engagement_individual{
    background-color:#E9CC4C;
  }
  &.all_rounder{
    background-color:#E9CC4C;
  }
}


.slides-container{
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slides{
  display: flex; /* Hace que los slides se alineen horizontalmente */
  transition: transform 0.5s ease-in-out; /* Añade una transición suave cuando se cambien los slides */
  flex-direction: row;
  overflow-x: auto;
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  user-select: none;
  cursor-events: none;

}


.slide-indicator{
  background-color: $purple;
  width: 17px;
  height: 17px;
  margin: 0 5px;
  border-radius: 50%;
  opacity: 0.3;
  &.active{
    opacity: 1;
  }
}

.slide {
  min-width: 100%; /* Hace que cada slide ocupe el 100% del ancho del contenedor */
  flex-shrink: 0; /* Evita que los slides se reduzcan de tamaño */
  box-sizing: border-box; /* Asegura que padding y border no afecten el ancho total del slide */
  padding: 0 20px; /* Opcional: Añade espacio interno a los slides */
  cursor: grab; /* Show a grabbing cursor when hovering over slides */

  img{
    margin: auto;
    pointer-events: none;
  }
}

.slides:active {
  cursor: grabbing; /* Change cursor to grabbing when dragging */
}


.text-dark{
  color:#53565A;
}

.popup-container{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  transition: all ease 0.4s;

  &.hide{
    background-color: rgba(0,0,0,0);
  }

  .popup-content{
    width: 100%;
    max-width: 524px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    padding-bottom: 44px;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);

    .close{
      position: absolute;
      top: 0px;
      right: 15px;
      cursor: pointer;
      font-size: 2em;;
    }
  }

  .bottom-card{
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding-top: 40px;
    transition: all ease 0.4s;

    &.hide{
      transform: translateY(100%);
    }


    .slide-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        height: 364px;
        max-height: 34vh;
        margin: 20px auto;
        
      }
    }

    &::after{
      content: "";
      border-radius: 4px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 6px;
      background: #E3EBEC;
    }

  }
}

.selectable{
  cursor: pointer;
  &:hover{
    transform:scale(1.5,1.5);

    .tooltip{
      display: inherit;
    }
  }

  .tooltip{
    position: relative;
    display: none;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    width: 70px;
    background-color: white;
    border-radius: 12px;
    text-align: center;
    padding: 8px;
    transform:translate(-50%, -100%);
    z-index: 1;
    b{
      font-size:14px;
    }
    p{
      font-size: 10px;
      margin-top: -5px;
    }
  }
}

.primary-button{
  width: 100%;
  background: $purple;
  color: white;
  font-weight: bold;
  padding: 24px 20px;
  border-radius: 14px;
  margin: 20px 0;
}

.badge-background {
  width: 66px; 
  height: 66px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.badge-background.engagement {
  background-color: #DE7A04;
}

.badge-background.caring {
  background-color: #872B93;
}

.badge-background.sentiment {
  background-color: #30B4BC;
}

.badge-counter-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px; 
  right: -10px; 
  font-size: 12px;
  font-weight: 600;
  background-color: white; 
}



.badge-counter-circle.engagement {
  border: 2px solid #DE7A04;
}

.badge-counter-circle.caring {
  border: 2px solid #872B93;
}

.badge-counter-circle.sentiment {
  border: 2px solid #30B4BC;
}

.filter-white {
  filter: brightness(0) invert(1); 
}

.badge-score-counter {
  width: 22px; 
  height: 22px; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #492CBB;
  background-color: #EDEAF9; 
  border: none;
  margin-top: 4px; 
}

.badge-score-counter-achievements {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #492CBB;
  background-color: #EDEAF9;
  margin-top: 4px; 
  position: relative; 
}

.greyed-out {
  filter: grayscale(100%);
  opacity: 0.5;
}




/* element fades from in about 30px down of it regular position and a small scale effect*/
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* element fades from in about 30px down of it regular position and a small scale effect*/
@keyframes fade-in-30 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
