.container {
  background-color: white;
  border-radius: 16px;
  margin-top: 24px;
  padding: 16px;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0e0e2c;
  margin-bottom: 16px;
  text-align: center;
}

.badge-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 32px;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge-background {
  width: 66px;
  height: 66px;
  background-color: var(--badge-color);
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-icon {
  width: 30px;
  height: 30px;
}

.badge-counter-circle {
  border: 2px solid var(--badge-border-color);
  color: var(--badge-border-color);
  background-color: white;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 50%;
  font-weight: bold;
}

.badge-text {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #0e0e2c;
}
